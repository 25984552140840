<template>
    <section class="section">
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Annual Projects</h5>

              <!-- Default Tabs -->
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Home</button>
                </li>
                
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="material-tab" data-bs-toggle="tab" data-bs-target="#material" type="button" role="tab" aria-controls="material" aria-selected="false">Materials</button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="material-list-tab" data-bs-toggle="tab" data-bs-target="#material-list" type="button" role="tab" aria-controls="material-list" aria-selected="false">Material List</button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Project Feedback</button>
                </li>
              </ul>
              <div class="tab-content pt-2" id="myTabContent">
                <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                  <!-- <ListGroups /> -->
                  <ProjectVue2 />
                </div>
                <div class="tab-pane fade" id="material" role="tabpanel" aria-labelledby="material-tab">
                  <Materials />
                </div>
                <div class="tab-pane fade" id="material-list" role="tabpanel" aria-labelledby="material-list-tab">
                  <ProjectsAndMaterials />
                </div>
                <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                  <ProjectsWithComments />
                </div>
               
              </div><!-- End Default Tabs -->

            </div>
          </div>


        </div>

      </div>
    </section>
</template>
<script>
import ListGroups from './components/ListGroups.vue';
import ProjectVue2 from './components/ProjectVue2.vue';
import Materials from './components/Materials.vue';
import ProjectsAndMaterials from './components/ProjectsAndMaterials.vue';
import ProjectsWithComments from './components/ProjectsWithComments.vue';
export default{
  components:{
    ListGroups,
    ProjectVue2,
    Materials,
    ProjectsAndMaterials,
    ProjectsWithComments,
  }

}
</script>