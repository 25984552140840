<template>
    <div>
      <h2>Groups</h2>
      <ul>
        <li v-for="group in groups" :key="group.id">
          {{ group.name }} 
          (Group Leader: {{ group.leader.firstname }} {{ group.leader.lastname }})

          <!-- Show Add Member button for the group leader -->
           <button
              @click="showAddMemberForm(group.id)" 
              class="btn btn-success btn-sm">
              Add Member
            </button>
          <ul>
            <li v-for="member in group.members" :key="member.id">
              {{ member.user.firstname }} {{ member.user.lastname }}
            </li>
          </ul>
          <!-- Add member form -->
       <div v-if="showAddMember && selectedGroupId === group.id" class="add-member-form">
        <h3> Add Member to {{ getGroupName(selectedGroupId) }}</h3>

        <select v-model="selectedStudentId" class="form-control d-inline-block w-auto me-2">
          <option v-for="student in availableStudents" :key="student.id" :value="student.id">
            {{ student.firstname }} {{ student.lastname }}
          </option>
        </select>
        <button @click="addMember">Submit</button>
        <button @click="cancelAddMember">Cancel</button>
        <p v-if="message">{{ message }}</p>
       </div>
        </li>
      </ul>
      
      <button @click="toggleCreateGroup" class="btn btn-primary">
        {{ showCreateGroup ? 'Cancel' : 'Create Group' }}
      </button>
      <div v-if="showCreateGroup" class="create-group-form">
        <input
          v-model="groupName"
          type="text" class="form-control d-inline-block w-auto me-2"
          placeholder="Enter Group name"
          required />
          <button @click="createGroup" class="btn btn-dark"> Submit</button>
      </div>
      <p v-if="message">{{ message }}</p>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import Swal from 'sweetalert2';
  export default {
    data() {
      return {
        groups: [],
        showCreateGroup: false,
        groupName: '',
        message:'',
        showAddMember: false,
        selectedGroupId: null,
        availableStudents: [],
        selectedStudentId: null,
        loggedInUserId: 20,
      };
    },
    methods: {
      async getGroups() {
            this.loading = true;
            try {
                let response = await axios.get('/groups');
                this.groups = response.data;
            } catch (error) {
                console.error("Error fetching clubs: ", error);
            } finally {

                this.loading = false;
            }
        },
        toggleCreateGroup(){
          this.showCreateGroup = !this.showCreateGroup;
          this.groupName ='';
          this.message ='';
        },
        async createGroup(){
          if(!this.groupName.trim()){
            this.message = 'Group name is required';
            return;
          }
          try {
            const response =await axios.post('/groups',{
              name: this.groupName,
            });
            await Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: 'Group created successfully!',
                        confirmButtonText: 'OK'
                    });
                    this.getGroups();
          }catch(error){
            this.message = error.response.data.message || 'An error occured';
          }
        },
        async showAddMemberForm(groupId){
          this.selectedGroupId = groupId;
          this.showAddMember = true;
          this.message ='';
          this.selectedStudentId = null;
          try{
            const response = await axios.get(`/groups/${groupId}/available-students`);
            this.availableStudents = response.data;
          }catch(error){
            this.message = 'Failed to laod available students.';
            console.error(error);
          }
        },
        cancelAddMember(){
          this.showAddMember = false;
          this.selectedGroupId = null;
        },
        getGroupName(groupId){
          const group = this.groups.find((g) => g.id === groupId);
          return group ? group.name : '';
        },
        async addMember() {
          if(!this.selectedStudentId){
            this.message = 'Please select a student to add to this group';
            return;
          }
          try{
            const response = await axios.post(`/groups/${this.selectedGroupId}/add-member`,{
              user_id: this.selectedStudentId,
            });
            this.message = response.data.message;
          this.showAddMember = false;
          await this.getGroups();
          }catch(error)
          {
            this.message = error.response?.data?.message || 'An error occured';
          }
         
        }
       
    },
    created(){
      this.getGroups();
    }
  };
  </script>
  <style>
  .create-group-form {
    margin-top: 1rem;
  }
  .add-member-form {
    margin-top: 1rem;
  }
  </style>