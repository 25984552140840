<template>
    <section class="section">
      <div class="row align-items-top">
        <div class="col-lg-12">
            <div v-if="loading" class="text-center mt-4">
                <span>Loading...</span>
                <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <div v-else>
          <div class="card" v-if="projects.length ===0">
            <div class="card-body">
              <p class="mt-2">No project registered yet</p>
            <button
              @click="showAddProjectForm()" 
              class="btn btn-success btn-sm mb-3">
              Add Project
            </button>
            </div>
          </div>
          <div class="card" v-else>

            <div class="card-body" >
              <h5 class="card-title">Projects</h5>
              <div class="accordion mb-3" id="accordionExample" v-for="project in projects" :key="project.id">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOne">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                       {{ project.title }}
                    </button>
                  </h2>
                  <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <div v-if="isLeader(project)">
                        <textarea v-model="project.description" rows="10" class="form-control mb-2"></textarea>
                      <button @click="updateDescription(project)" class="btn btn-primary btn-sm mt-2">Save Changes</button>
                      </div>
                      
                    <div v-else>
                        {{ project.description }}
                      </div>                   
                    </div>
                    <hr />
                    <span class="badge bg-warning text-dark ms-3 mb-3">{{ project.status }}</span>

                    <span class="badge bg-info ms-2 mb-3">Mentor: {{ getMentorName(project.mentor_id) }}</span>
                    <button v-if="isLeader(project)" class="btn btn-danger btn-sm ms-2" @click="openEditMentorModal(project)">Change Mentor</button>

                    <div v-if="showEditMentor" class="add-project-form">
                        <h5>Edit Project Mentor</h5>
                        <select id="project-mentor" v-model="newMentor.mentor_id" class="form-control d-inline-block w-auto me-5 mb-4">
                          <option value="">Select Mentor</option>  
                          <option v-for="mentor in mentors" :key="mentor.id" :value="mentor.id">
                                {{ mentor.firstname }} {{ mentor.lastname }}
                            </option>
                        </select><br />
                        
                        <button @click="updateMentor(project.id)" class="btn btn-primary">Save Changes</button>
                        <button @click="cancelEditMentor" class="btn btn-secondary ms-2">Cancel</button>
                        <p v-if="message">{{ message }}</p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingTwo">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      Project Proposal #2
                    </button>
                  </h2>
                  <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                    <div class="accordion-body" v-if="!project.proposal">
                      <p>No Project Proposal submitted</p>
                <button class="btn btn-primary btn-sm" 
                @click="showAttachProposalForm(project.id)">
                <i class="bi bi-paperclip"></i></button>
                <div v-if="showAttachProposal">
                    <input type="file" @change="handleFileUpload($event, project.id)">
                    <button @click="attachProposal(project.id)">Attach</button>
                </div>
              </div>
              <div v-else>
                <a :href="`/storage/${project.proposal}`" target="_blank" class="mt-4 ms-3">View Proposal</a>
              </div>
                    </div>
                  </div>
                <!-- </div> -->
                
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingThree">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      Project members
                    </button>
                  </h2>
                  <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <ul v-if="project.members.length >0">
                        <li v-for="member in project.members" :key="member.id">
                          {{ member.firstname }} {{ member.lastname }}
                          <button v-if="isLeader(project)" @click="removeMember(member.id)" class="btn btn-danger btn-sm ms-2">Remove</button>
                        </li>
                      </ul>
                      <p v-else>No members have been associated with this project yet.</p>
                      <!-- Show Add Member button if the logged-in user is the leader -->
                      <button v-if="isLeader(project)" @click="openAddMemberModal(project)" class="btn btn-success btn-sm">
                            Add Members
                        </button>
                    </div>
                  </div>
                </div>
              </div><!-- End Default Accordion Example -->
            </div>         
          </div><!-- End Card with header and footer -->
        </div>     
      </div>
      <div class="col-md-3">
        <button class="btn btn-success btn-sm mt-3" @click="showAddProjectForm" v-if="projects.length >0">Register Another Project</button>
      </div>
    </div>
    </section>

    <div>
       
          <!-- Add member form -->
          <div v-if="showAddProject" class="add-project-form">
    <h3>Register Project</h3>
    <input id="project-title" v-model="newProject.title" placeholder="Project Title" class="form-control mb-2" />
    
    <textarea id="project-description" v-model="newProject.description" placeholder="Project Description" class="form-control mb-2"></textarea>

    <select id="project-mentor" v-model="newProject.mentor_id" class="form-control d-inline-block w-auto me-5 mb-4">
      <option value="">Select Mentor</option>  
      <option v-for="mentor in mentors" :key="mentor.id" :value="mentor.id">
            {{ mentor.firstname }} {{ mentor.lastname }}
        </option>
    </select><br />
    
    <button @click="addProject" class="btn btn-primary">Save Project</button>
    <button @click="cancelAddProject" class="btn btn-secondary ms-2">Cancel</button>
    <p v-if="message">{{ message }}</p>
</div>



       <!-- Add students to project modal -->
        <div v-if="selectedProject && showAddMember">
          <h3>Add member to {{ selectedProject.title }}</h3>
          <select v-model="selectedUserId" class="form-control d-inline-block w-auto me-2 ">
            <option v-for="student in availableStudents" :key="student.id" :value="student.id">
              {{ student.firstname }} {{ student.lastname }}
            </option>
          </select>
          <button @click="addMember(selectedUserId)" class="btn btn-primary btn-sm">Add Member</button>
        </div>
       
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  export default {
    data() {
      return {
        projects: [],
        mentors:[],
        message:'',
        success: false,
        loading: false,
        showAddProject: false, 
        showAddMember: false,
        showEditMentor: false,
        showAttachProposal: false,   
        selectedFile: {},
        currentUser: {},
        selectedProject:null,
        selectedUserId:null,
        allStudents:[],
        members:{},
        newProject: {
            title:'',
            description:'',
            mentor_id:'',
        },
        newMentor:{
          mentor_id:'',
        },
        proposal: {
            proposal: '',
        }
      };
    },
    computed:{
      availableStudents(){
        const memberIds = this.selectedProject ? this.selectedProject.members.map(member=>member.id) : [];
        return this.allStudents.filter(student => !memberIds.includes(student.id));
      },
    },
    methods: {
      async getProjects() {
            this.loading = true;
            try {
                let response = await axios.get('/projects');
                this.projects = response.data;
                
            } catch (error) {
                console.error("Error fetching Projects: ", error);
            } finally {

                this.loading = false;
            }
        },
        async getCurrentUser()
        {
          let response = await axios.get('/user');
          this.currentUser = response.data;
          console.log("current user: ", this.currentUser.id);

        },
        isLeader(project){
          if(project && project.leader){
          return project.leader.id === this.currentUser.id;
          }
          return false;
        },
        openAddMemberModal(project){
          this.selectedProject = project;
          this.showAddMember=true;
        },
        openEditMentorModal(project){
          this.selectedProject = project;
          this.showEditMentor=true;
        },
        async getStudents(){
          try{
            const response = await axios.get('/students');
            this.allStudents = response.data;
          }catch(error) {
            console.error('Failed to fetch students:', error);
          }
        },
       
        async getMentors(){
            try{
            const response = await axios.get('/mentors');
            this.mentors = response.data;
            }catch(error){
                console.error("Error fetching mentors", error);
            }
        },
        async showAddProjectForm(){
          this.showAddProject = true;
          this.message ='';

          this.newProject = {
            title: "",
            description:"",
            mentor_id: "",
          };
        },
        showAttachProposalForm(){
            this.showAttachProposal = true;
            this.message = "";
            this.proposal={
                proposal :"",
            }
                },
            handleFileUpload(event, projectId){
            this.selectedFile[projectId] = event.target.files[0];
        },
        async attachProposal(projectId){
           const file = this.selectedFile[projectId];
           if(!file){
            this.message[projectId]='Please select a file first';
            this.success=false;
            return;
           }
            const formData = new FormData();
           
            formData.append('proposal', file);
            this.loading = true;
            
            try{
                await axios.post(`/project/${projectId}/attach-proposal`, formData, {
                  headers: {'Content-Type': 'multipart/form-data'},
                });
                this.message = response.data.message;
                this.success = true;
                this.getProjects();
                
            }catch(error){
              console.error('Error submitting proposal', error);
              this.message = error.response?.data?.message || 'An error occured. Please try again.';
              this.success = false;
            }finally{
                this.loading = false;
            }
        },
        async addMember(userId){
          try{
            const response = await axios.post(`/projects/${this.selectedProject.id}/add-member`, {
              user_id: userId,
            });
            this.message = response.data.message;
            this.showAddMember = false;
            this.getProjects();
            
          } catch(error){
            console.error(error.response.data.message || 'Failed to add member');
          }
        },
        async removeMember(userId){
          if(!this.selectedProject){
            this.selectedProject = this.projects[0].id;
          }
          try{
            const response = await axios.delete(`/projects/${this.selectedProject}/members/${userId}`);
            this.message = response.data.message;
            await this.getProjects();
          }catch(error){
            console.error('Failed to remove member', error);
            if(error.response && error.response.data){
              this.message = error.response.data.message || 'Failed to remove member';
            }else{
              this.message ='An unexpected error occured ';
            }
          }
        },
        async updateMentor(project)
        {
          const projectId = this.projects[0].id;
          try{

            const response = await axios.put(`/projects/${projectId}/mentor`, {
              mentor_id: this.newMentor.mentor_id,
            });
            this.message = response.data.message;
            await this.getProjects();
          }catch(error){
            console.error("Error updating mentor", error);
            this.message = "Failed to update mentor";
          }
        },
        async updateDescription(project){
          try{
            const response = await axios.put(`/projects/${project.id}/description`, {
              description: project.description,
            });
            await this.getProjects();
            this.message = response.data.message;
          }catch(error)
          {
            console.error(error);
            this.message = error.response?.data?.message || 'An error occured while updating the description';
          };
        },

        cancelAddProject(){
          this.showAddProject = false;
        },
        
        cancelEditMentor(){
          this.showEditMentor = false;
        },
        getMentorName(mentorId){
          const mentor = this.mentors.find((m) => m.id === mentorId);
          return mentor ? `${mentor.firstname} ${mentor.lastname}` : "N/A";
        },
        async addProject() {
          if(!this.newProject.title||!this.newProject.description){
            this.message = 'Please fill out all project details';
            return;
          }
          try{
            const response = await axios.post(`/projects`,{
              title: this.newProject.title,
              description: this.newProject.description,
              mentor_id: this.newProject.mentor_id,
            });
            this.message = response.data.message;
          this.showAddProject = false;
          await this.getProjects();
          }catch(error)
          {
            console.error(error);
            this.message = error.response?.data?.message || 'An error occured';
          }
         
        },   
    },
    created(){
      this.getProjects();
      this.getMentors();
      this.getStudents();
      this.getCurrentUser();
    }
  };
  </script>
  <style>
  .create-group-form {
    margin-top: 1rem;
  }
  .add-member-form {
    margin-top: 1rem;
  }
  </style>