import axios from "axios";
import router from "./router";

axios.defaults.withCredentials = true;
axios.defaults.baseURL ="https://gashoragirls.com/api/public/api";
// axios.defaults.baseURL ="http://localhost:8000/api";


axios.interceptors.response.use(
    response => response,
    error=>{
        if(error.response && error.response.status === 401){
            localStorage.removeItem('token');
            localStorage.removeItem('user_role');
            router.push({name: 'Login'});
            return Promise.resolve();
        }
        return Promise.reject(error);
    }
);

export default axios;