<template>
    <div>
      <h2>Add Materials for Project: {{ projectId }}</h2>
  
      <form @submit.prevent="submitMaterials">
        <div v-for="(material, index) in materials" :key="index">
          <input 
            type="text" 
            v-model="material.material_name" 
            placeholder="Material Name"
            required 
          />
          <input 
            type="number" 
            v-model="material.quantity" 
            placeholder="Quantity" 
            required 
          />
          <button type="button" @click="removeMaterial(index)">Remove</button>
        </div>
  
        <button type="button" @click="addMaterial">Add Another Material</button>
        <button type="submit">Submit Materials</button>
      </form>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        materials: [
          { material_name: '', quantity: 1 }, // Default material input
        ],
      };
    },
    computed: {
        projectId(){
            return this.$route.params.projectId;
        },
    },
    methods: {
      addMaterial() {
        this.materials.push({ material_name: '', quantity: 1 });
      },
      removeMaterial(index) {
        this.materials.splice(index, 1);
      },
      submitMaterials() {
        axios.post(`/projects/${this.projectId}/materials`, { materials: this.materials })
          .then(response => {
            alert(response.data.message);
            this.$router.push({ name: 'Projects' }); // Navigate back to projects list
          })
          .catch(error => {
            console.error('Error adding materials:', error);
          });
      },
    },
  };
  </script>
  