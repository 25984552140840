<template>
    <!-- ======= Header ======= -->
    <header id="header" class="header fixed-top d-flex align-items-center">
        <div class="d-flex align-items-center justify-content-between">
            <a href="index.html" class="logo d-flex align-items-center">
                <img src="@/assets/img/Logo.jpg" alt="">
                <span class="d-none d-lg-block">Extra-Curricula</span>
            </a>
            <i class="bi bi-list toggle-sidebar-btn" @click="toggleSidebar"></i>
        </div><!-- End Logo -->

        <nav class="header-nav ms-auto">
            <ul class="d-flex align-items-center">
                <li class="nav-item dropdown pe-3">
                    <a class="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
                        <span class="d-none d-md-block dropdown-toggle ps-2">{{ user.firstname
                            }} {{ user.lastname }}</span>
                    </a><!-- End Profile Iamge Icon -->

                    <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                        <li>
                            <router-link class="dropdown-item d-flex align-items-center" to="/student/edit-profile">
                                <i class="bi bi-person"></i>
                                <span>My Profile</span>
                            </router-link>
                        </li>
                        <li>
                            <hr class="dropdown-divider">
                        </li>
                        <li>
                            <button @click="logout" class="dropdown-item d-flex align-items-center" href="#">
                                <i class="bi bi-box-arrow-right"></i>
                                <span>Sign Out</span>
                            </button>
                        </li>
                    </ul><!-- End Profile Dropdown Items -->
                </li><!-- End Profile Nav -->
            </ul>
        </nav><!-- End Icons Navigation -->
    </header><!-- End Header -->

    <!-- ======= Sidebar ======= -->
    <aside id="sidebar" :class="['sidebar', { 'sidebar-open': isSidebarOpen }]">
        <button class="close-sidebar-btn" @click="closeSidebar" v-if="isSidebarOpen && windowWidth < 992">
            &times;
        </button>

        <ul class="sidebar-nav" id="sidebar-nav">
            <li class="nav-item">
                <router-link class="nav-link " :to="{ name: 'student.dashboard' }">
                    <i class="fas fa-home"></i>
                    <span>Home</span>
                </router-link>
            </li><!-- End Dashboard Nav -->

            <li class="nav-item">
                <router-link class="nav-link " :to="{ name: 'clubs.sports' }">
                    <i class="bi bi-people"></i>
                    <span>Clubs & Sports</span>
                </router-link>
            </li><!-- End Dashboard Nav -->
            <li class="nav-item">
                <router-link class="nav-link " :to="{ name: 'student.projects' }">
                    <i class="fas fa-chart-line"></i>
                    <span>Projects</span>
                </router-link>
            </li><!-- Projects -->

        </ul>
    </aside>
    <main id="main" class="main">
        <section class="section dashboard">
            <router-view />
        </section>
    </main>
    <!-- ======= Footer ======= -->
    <footer id="footer" class="footer">
        <div class="copyright">
            &copy; Copyright <strong><span>GGAST Extra Curricular Department</span></strong>. All Rights Reserved
        </div>
    </footer><!-- End Footer -->
</template>
<script>
import axios from 'axios';
export default {
    data() {
        return {
            user: "",
            isSidebarOpen: false,
            windowWidth: window.innerWidth,
        }
    },
    methods: {
        async userProfile() {
            await axios.get("/user").then((response) => {
                this.user = response.data;
            });
        },
        async logout() {
            try {
                await axios.post('logout');
                this.$router.push('/login');

            } catch (error) {
                console.error("Logout failed: ", error);
            }
        },
        toggleSidebar() {
            this.isSidebarOpen = !this.isSidebarOpen;
        },
        closeSidebar() {
            this.isSidebarOpen = false;
        },
        handleResize() {
            this.windowWidth = window.innerWidth;
            if (this.windowWidth >= 992) {
                this.isSidebarOpen = true;
            } else {
                this.isSidebarOpen = false;
            }
        }
    },
    created() {
        axios.defaults.headers.common["Content-Type"] = "application/json";
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + localStorage.getItem("token");
        this.userProfile();

        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    }
}
</script>
<style>
/* Sidebar Styling */
.sidebar {
    width: 250px;
    height: 100vh;
    background-color: #f8f9fa;
    transition: transform 0.3s ease-in-out;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    overflow-y: auto;
}

.sidebar.sidebar-open {
    transform: translateX(0);
}

.sidebar:not(.sidebar-open) {
    transform: translateX(-100%);
}

.close-sidebar-btn {
    background: none;
    border: none;
    font-size: 1.5rem;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.main {
    margin-left: 250px;
    transition: margin-left 0.3s ease-in-out;
}

@media (max-width: 992px) {
    .main {
        margin-left: 0;
    }

    .sidebar {
        width: 200px;
    }

    .sidebar.sidebar-open {
        transform: translateX(0);
    }

    .close-sidebar-btn {
        display: block;
    }
}

/* Profile Name Styling */
.nav-profile span {
    font-size: 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media (max-width: 768px) {
    .nav-profile span {
        font-size: 0.85rem;
    }
}
</style>