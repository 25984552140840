<template>
  <div>
    <h3>Select a Projects to add materials</h3>
    <ul class="list-unstyled">
      <li v-for="project in projects" :key="project.id" class="mb-3">
        <label>
          <input 
            type="radio" 
            :value="project" 
            v-model="selectedProject"
          />
          {{ project.title }}
        </label>
      </li>
    </ul>

    <div v-if="selectedProject">
      <h3>Selected Project</h3>
      <p><strong>Title:</strong> {{ selectedProject.title }}</p>
      <p><strong>Description:</strong> {{ selectedProject.description }}</p>

      <button type="button" 
      class="btn btn-primary"
      data-bs-toggle="modal"
      data-bs-target="#addMaterialsModal"
       @click="openModal">Add Materials</button>
    </div>

    <!-- Bootstrap Modal -->
    <div 
      class="modal fade" 
      id="addMaterialsModal" 
      tabindex="-1" 
      aria-labelledby="addMaterialsModalLabel" 
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="addMaterialsModalLabel" v-if="selectedProject">Add Materials for {{ selectedProject.title }}</h5>
            <button 
              type="button" 
              class="btn-close" 
              data-bs-dismiss="modal" 
              aria-label="Close"
              @click="removeModalBackdrop"
            ></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="submitMaterials">
              <div v-for="(material, index) in materials" :key="index" class="mb-3 d-flex align-items-end">
                <div class="col-md-6 me-2">
                  <label for="materialName" class="form-label">Material Name</label>
                  <input 
                    type="text" 
                    class="form-control" 
                    v-model="material.material_name" 
                    placeholder="Material Name"
                    required 
                  />
                </div>
                <div class="col-md-4 me-2">
                  <label for="quantity" class="form-label">Quantity</label>
                  <input 
                    type="number" 
                    class="form-control" 
                    v-model="material.quantity" 
                    placeholder="Quantity" 
                    required 
                  />
                </div>
                <div class="col-md-2">
                <button 
                  type="button" 
                  class="btn btn-danger btn-sm" 
                  @click="removeMaterial(index)"
                >
                  Remove
                </button>
              </div>
                <hr />
              </div>

              <button 
                type="button" 
                class="btn btn-secondary" 
                @click="addMaterial"
              >
                Add Another Material
              </button>
            </form>
          </div>
          <div class="modal-footer">
            <button 
              type="button" 
              class="btn btn-secondary" 
              data-bs-dismiss="modal"
              @click="removeModalBackdrop"
            >
              Close
            </button>
            <button 
              type="button" 
              class="btn btn-primary" 
              @click="submitMaterials"
            >
              Save Materials
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { Modal } from '@/assets/vendor/bootstrap/js/bootstrap.bundle.min';
export default {
  data() {
    return {
      projects: [],
      selectedProject: null,
      materials: [
        {
          material_name:'',
          quantity:1
        },
      ],
    };
  },
  mounted() {
    this.fetchProjects();
  },
  methods: {
    fetchProjects() {
      axios.get('/user/projects') // Adjust endpoint if necessary
        .then(response => {
          this.projects = response.data.projects;
        })
        .catch(error => {
          console.error('Error fetching projects:', error);
        });
    },
    openModal() {
      this.resetMaterials();
      const modal = new Modal(document.getElementById('addMaterialsModal'));
      modal.show();
    },
    resetMaterials() {
      this.materials = [{ material_name: '', quantity: 1 }];
    },
    addMaterial() {
      this.materials.push({ material_name:'', quantity: 1});
    },
    removeMaterial(index){
      this.materials.splice(index,1);
    },
    removeModalBackdrop() {
      const backdrop = document.querySelector('.modal-backdrop');
      if (backdrop) {
        backdrop.parentNode.removeChild(backdrop);
      }
    },
    async submitMaterials(){
      if(!this.selectedProject){
        alert('Please select a project first');
        return;
      }
      await axios.post(`/projects/${this.selectedProject.id}/materials`, { materials: this.materials})
        .then((response) => {
          alert(response.data.message);
          this.resetMaterials();
          const modal = document.getElementById('addMaterialsModal');
          const bootstrapModal = bootstrap.Modal.getInstance(modal);
          bootstrapModal.hide();
          this.removeModalBackdrop();
        })
        .catch((error) =>{
          console.error('Error adding materials: ', error);
        })
    }
  },
};
</script>
