// src/auth.js
import axios from 'axios';

async function refreshToken() {
  const refreshToken = localStorage.getItem('refresh_token');
  try {
    const response = await axios.post('/refresh-token', { refreshToken });
    localStorage.setItem('token', response.data.token);
    return response.data.token;
  } catch (error) {
    console.error('Error refreshing token:', error);
    return null;
  }
}

export default refreshToken;
