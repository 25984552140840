<template>
    <div>
      <h3 class="mb-3 mt-3">My Mentored Projects</h3>
  
      <div v-for="project in projects" :key="project.id" class="card ">
        <div class="card-header">
          <h3>{{ project.title }}</h3>
          <p>Status: <strong>{{ project.status }}</strong></p>
        </div>
        <div class="card-body">
          <p class="mb-4" style="text-align: justify;"><strong>Project Description: </strong>
            {{ project.description }}
            <br /><br />
            <small><strong>Last Modified: </strong>{{ project.formatted_updated_at }}</small>
        </p>
  
            <span class="comments">
                <br /><br />
                <strong>Comments by:  </strong>
                <template v-if="project.comments.length >0">
                    <span>
                    <strong>{{ project.mentors.firstname }} {{ project.mentors.lastname }}</strong>
                    <p v-for="comment in project.comments">
                    {{ comment.comment }}
                </p>
                </span>
                </template>
                <template v-else>
                    <span>No Comment has been made</span>
                </template>
            </span>
        
          <!-- <h5>Comments</h5>
          <ul>
            <li v-for="comment in project.comments" :key="comment.id">
              <strong>{{ comment.user.firstname }}:</strong> {{ comment.comment }}
            </li>
          </ul> -->
  
          <textarea
            v-model="newComments[project.id]"
            class="form-control mb-2"
            placeholder="Add a comment"
          ></textarea>
          <button @click="addComment(project.id)" class="btn btn-primary mb-3">
            Add Comment
          </button>
  
          <select v-model="project.status" class="form-control mb-2">
            <option value="pending">Pending</option>
            <option value="approved">Approved</option>
            <option value="rejected">Rejected</option>
          </select>
          <button @click="updateStatus(project.id, project.status)" class="btn btn-success">
            Update Status
          </button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        projects: [],
        newComments: {}, // Tracks new comments for each project
      };
    },
    mounted() {
      this.fetchProjects();
    },
    methods: {
      // Fetch projects for the logged-in mentor
      async fetchProjects() {
        try {
          const response = await axios.get('/mentor/projects');
          this.projects = response.data;
        } catch (error) {
          console.error('Failed to fetch projects:', error);
        }
      },
      // Add a comment to a project
      async addComment(projectId) {
        const comment = this.newComments[projectId];
        if (!comment) return;
  
        try {
          await axios.post(`/mentor/project/${projectId}/comment`, { comment });
          this.fetchProjects(); // Refresh the project list
          this.newComments[projectId] = ''; // Clear the comment input
        } catch (error) {
          console.error('Failed to add comment:', error);
        }
      },
      // Update the project status
      async updateStatus(projectId, status) {
        try {
          await axios.patch(`/mentor/project/${projectId}/status`, { status });
          this.fetchProjects(); // Refresh the project list
        } catch (error) {
          console.error('Failed to update status:', error);
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .card {
    margin-bottom: 20px;
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 5px;
  }
  
  .comments {
    margin-left: 15px; /* Adds space between description and comments */
    font-style: italic; /* Optional styling for comments */
    color: #555;
    text-align: justify;
  }
  </style>
  
  