<template>
    <div>
      <h2>Registered Projects</h2>
      <button @click="downloadPDF" class="btn btn-primary mb-3"><i class="bi bi-file-pdf"></i></button>
      <table class="table table-bordered" id="projects-table">
        <thead>
          <tr>
            <th>#</th>
            <th>Project Title</th>
            <th>Description</th>
            <th>Members</th>
            <th>Mentor</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(project, index) in projects" :key="project.id">
            <td>{{ index + 1 }}</td>
            <td>{{ project.title }}</td>
            <td>{{ project.description }}</td>
            <td>
              <ol>
                <!-- Display Project Leader -->
                <li>
                  <strong>{{ project.leader.firstname }} {{ project.leader.lastname }} (Project Leader)</strong>
                </li>
                <!-- Display Other Members -->
                <li v-for="member in project.members" :key="member.id">
                  {{ member.firstname }} ({{ member.lastname }})
                </li>
              </ol>
            </td>
            <td>
              
                  {{ project.mentors.firstname }}  {{ project.mentors.lastname }}
               
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import html2pdf  from 'html2pdf.js';
  export default {
    data() {
      return {
        projects: [],
      };
    },
    async created() {
      await this.fetchProjects();
    },
    methods: {
      async fetchProjects() {
        try {
          const response = await axios.get('/projects-with-members');
          this.projects = response.data.projects;
        } catch (error) {
          console.error('Error fetching projects:', error);
        }
      },
      downloadPDF(){
        const element = document.getElementById('projects-table');
        const options = {
            margin: 10,
            filename: 'projects.pdf',
            image: {type:'jpeg', quality: 0.98},
            html2canvas: { scale: 2},
            jsPDF: {unit: 'mm', format: 'a4', orientation:'landscape'},
        };
        html2pdf().set(options).from(element).save();
      },
    },
  };
  </script>
  