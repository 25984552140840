<template>
    <div>
      <h4 class="mt-3"> Students with No project</h4>
      <button @click="downloadPDF" class="btn btn-primary mb-3"><i class="bi bi-file-pdf"></i></button>

      <div class="title">List of student who do not belong to any project</div>
      <table class="table table-bordered mt-3" id="students-with-no-projects">
        <thead>
          <tr>
            <th>#</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Registered Email</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(student, index) in unassignedStudents" :key="student.id">
            <td>{{ index + 1 }}</td>
            <td>{{ student.firstname }}</td>
            <td>{{ student.lastname }}</td>
            <td>{{ student.email }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
<script>
import axios from 'axios';
import html2pdf  from 'html2pdf.js';

export default {
  data() {
    return {
      unassignedStudents: []
    };
  },
  methods: {
    async fetchUnassignedStudents() {
      try {
        const response = await axios.get('/unassigned-students');
        this.unassignedStudents = response.data.students;
      } catch (error) {
        console.error('Error fetching unassigned students:', error);
      }
    },
    
    downloadPDF(){
        const element = document.getElementById('students-with-no-projects');
        const options = {
            margin: 10,
            filename: 'Unassigned Students.pdf',
            image: {type:'jpeg', quality: 0.98},
            html2canvas: { scale: 2},
            jsPDF: {unit: 'mm', format: 'a4', orientation:'landscape'},
        };
        html2pdf().set(options).from(element).save();
        
      },
  },
  created() {
    this.fetchUnassignedStudents();
  }
};
</script>
  