<template>
    <div>
      <h2>Projects, Materials, and Comments</h2>
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>#</th>
            <th>Project Title</th>
            <th>Description</th>
            <th>Materials</th>
            <th>Comments</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(project, index) in projects" :key="project.id">
            <td>{{ index + 1 }}</td>
            <td>{{ project.title }}</td>
            <td>{{ truncateDescription(project.description) }}</td>
            <td>
              <table class="table table-sm table-hover">
                <thead>
                  <tr>
                    <th>Material</th>
                    <th>Quantity</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(material, materialIndex) in project.materials" :key="materialIndex">
                    <td>{{ material.material_name }}</td>
                    <td>{{ material.quantity }}</td>
                  </tr>
                  <tr v-if="!project.materials.length">
                    <td colspan="2" class="text-center">No materials added</td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td>
              <ul>
                <li v-for="(comment, commentIndex) in project.comments" :key="commentIndex">
                  {{ comment.comment }} - <strong>{{ comment.author }}</strong>
                </li>
                <li v-if="!project.comments.length" class="text-muted">No comments yet</li>
              </ul>
            </td>
          </tr>
          <tr v-if="!projects.length">
            <td colspan="5" class="text-center">No projects available</td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
<script>
import axios from 'axios';

export default {
  data() {
    return {
      projects: [], // Store projects with materials and comments
    };
  },
  mounted() {
    this.fetchProjects();
  },
  methods: {
    fetchProjects() {
      axios
        .get('/user/projects-with-materials-and-comments') // Adjust the API endpoint
        .then((response) => {
          this.projects = response.data.projects;
        })
        .catch((error) => {
          console.error('Error fetching projects:', error);
        });
    },
    truncateDescription(description) {
      if (!description) return '';
      const words = description.split(' ');
      if (words.length > 30) {
        return words.slice(0, 30).join(' ') + '...';
      }
      return description;
    },
  },
};

</script>  